// Import our CSS
import styles          from '../css/app.pcss';

// Polyfills
import 'intersection-observer';
import objectFitImages from 'object-fit-images';
import 'promise-polyfill/src/polyfill';

// Other modules
import lozad                                   from 'lozad';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SlideUpDown                             from 'vue-slide-up-down';
import VTooltip                                from 'v-tooltip';
import Swiper                                  from 'swiper';
import { isMobile }                            from 'mobile-device-detect';
import VModal                                  from 'vue-js-modal';
import Notifications                           from 'vue-notification';

// Vee-validate
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required }                                from 'vee-validate/dist/rules';

// Vee-validate rules
extend('required', {
    ...required,
    message: 'Dit veld is vereist.'
});

extend('email', {
    ...email,
    message: 'Dit is geen geldig e-mail adres.'
});


window.lozadObserver = lozad('.lozad', {
        loaded: el => {
            el.classList.add('lozad-loaded');
        }
    }
);

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: axios } = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    const vClickOutside = await import( /* webpackChunkName: "v-click-outside" */ 'v-click-outside');
    
    Vue.use(VueCookies);
    Vue.use(VTooltip, {
        defaultDelay: 100,
        defaultTrigger: 'hover click'
    });
    Vue.use(VModal, { dialog: true });
    Vue.use(Notifications);
    
    Vue.component('slide-up-down', SlideUpDown);
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationProvider', ValidationProvider);
    
    window.axios = axios;
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        
        directives: {
            clickOutside: vClickOutside.directive
        },
        
        components: {
            'block-testimonials': () => import(/* webpackChunkName: "block-testimonials" */ '../vue/BlockTestimonials.vue'),
            'modal-default': () => import(/* webpackChunkName: "modal-default" */ '../vue/ModalDefault.vue'),
            'form-medicineroll': () => import(/* webpackChunkName: "form-medicinerole" */ '../vue/FormMedicineroll.vue'),
            'form-prescription': () => import(/* webpackChunkName: "form-prescription" */ '../vue/FormPrescription.vue'),
            'algolia-block': () => import(/* webpackChunkName: "webshop" */ '../vue/Algolia.vue'),
            'promotions-block': () => import(/* webpackChunkName: "promotion" */ '../vue/Promotions.vue'),
            'account-profile': () => import(/* webpackChunkName: "account-profile" */ '../vue/AccountProfile.vue')
        },
        
        data: {
            // General
            isMounted: false,
            isMobile: isMobile,
            mobileNavIsOpen: false,
            mobileLevelsOpen: [],
            animInfoBanner: false,
            passwordFieldType: 'password',
            categoriesTree: [],
            brandsTree: [],
            
            // Cookie consent
            showCookieConsent: false,
            
            // Navigation
            mastheadHeight: 0,
            fixateNav: false,
            lastScrollTop: 0,
            hideMasthead: false,
            webshopNavTarget: '',
            webshopNavPreviousTarget: '',
            webshopNavIsOpen: false,
            navWebshopWrapper: null,
            navWebshop: null,
            navWebshopItems: null,
            autoNavWebshopList: null,
            autoNavWebshopMore: null,
            searchBar: null,
            navWebshopItemsMaxWidth: 1190,
            animFrameTimer: null,
            showOverflowNav: false,
            existsOverflowNav: false,
            movedElWidths: [],
            navWebshopTotalWidth: 0,
            
            // Sticky aside navigation
            targetLinks: null,
            
            // Dashboard
            newUserEmail: '',
            
            // Notice
            generalErrorMsg: 'Er ging iets mis.<br>Probeer later opnieuw of contacteer onze helpdesk.',
            
            // Commerce
            cartCount: 0,
            stepperInput: 1,
            showNewshippingAddressForm: false,
            showNewbillingAddressForm: false,
            
            // Registration
            userBirthdayDay: '',
            userBirthdayMonth: '',
            userBirthdayYear: '',
            registerAccountBrowserValidation: false,
            
            // Accordion
            openItem: ''
        },
        
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
            window.addEventListener('resize', this.onWindowResize);
            
            // Mobile webshop navigation
            this.getCategoriesTree();
        },
        
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
            window.removeEventListener('resize', this.onWindowResize);
        },
        
        mounted() {
            window.lozadObserver.observe();
            this.isMounted = true;
            
            if (window.currentUserId) {
                this.setBirthday();
            }
            
            this.updateCartCount();
            this.checkIfAddressesExist();
            
            if (this.$refs.infoBanner) {
                this.toggleAnimInfoBanner();
            }
            
            /* Show cookie consent with delay */
            setTimeout(() => {
                vm.showCookieConsent = true;
            }, 600);
            
            // Navigation
            let navFixed = document.getElementById('nav--fixed');
            if (navFixed) {
                this.mastheadHeight = navFixed.clientHeight;
            }
            
            // Sticky aside navigation
            if (this.$refs.stickyNavAside) {
                this.targetLinks = this.$refs.stickyNavAside.querySelectorAll('a');
            }
            
            this.onWindowScroll();
            this.onWindowResize();
        },
        
        methods: {
            onWindowResize() {
                if (this.$refs.stickyNavAside) {
                    this.$refs.stickyNavAside.style.top = this.calculateStickyTop();
                }
                
                if (this.$refs.infoBanner) {
                    this.toggleAnimInfoBanner();
                }
            },
            
            toggleAnimInfoBanner() {
                this.animInfoBanner = this.$refs.infoBannerContent.clientWidth > this.$refs.infoBanner.clientWidth;
            },
            
            onWindowScroll() {
                if (!this.mobileNavIsOpen) {
                    this.fixateNav = window.pageYOffset > this.mastheadHeight / 3;
                    
                    let scrollTop = window.pageYOffset;
                    this.hideMasthead = (scrollTop > this.lastScrollTop) && (scrollTop > this.mastheadHeight / 3);
                    
                    this.lastScrollTop = scrollTop;
                }
                
                let fromTop = window.pageYOffset;
                
                if (this.targetLinks !== null) {
                    this.targetLinks.forEach(link => {
                        let section = document.querySelector(link.hash);
                        
                        if (section.getBoundingClientRect().top > window.innerHeight * 30 / 100 &&
                            section.getBoundingClientRect().top < window.innerHeight * 60 / 100) {
                            let linkParent = link.parentElement;
                            if (linkParent.previousElementSibling) {
                                linkParent.previousElementSibling.classList.remove("active");
                            }
                            
                            if (linkParent.nextElementSibling) {
                                linkParent.nextElementSibling.classList.remove("active");
                            }
                            
                            linkParent.classList.add("active");
                        }
                    });
                }
            },
            
            calculateStickyTop() {
                // Buggy
                //return Math.floor(document.querySelector('.list--numbered div:first-of-type h3').getBoundingClientRect().top) + 'px';
                return Math.floor(window.innerHeight * 40 / 100) + 'px';
            },
            
            openCloseMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                const navMobilePanel = document.getElementById('nav-mobile-panel');
                this.mobileNavIsOpen ? disableBodyScroll(navMobilePanel) : enableBodyScroll(navMobilePanel);
            },
            
            confirmCookieConsent() {
                let vm = this;
                
                VueCookies.set('farmazorgCookieConsent', 'confirmed', "1y");
                setTimeout(() => {
                    vm.showCookieConsent = false;
                }, 200);
            },
            
            getCategoriesTree() {
                let vm = this;
                
                // Get pageCount and first batch of 100 results
                let p = axios.get("/elapi/webshop-tree.json?type=flat")
                    .then(response => {
                        let pageCount = response.data.meta.pagination.total_pages;
                        vm.categoriesTree = response.data.data;
                        return pageCount;
                    })
                    .then(pageCount => {
                        if (pageCount > 1) {
                            // i = 2 because we already fetched batch 1
                            for (let i = 2; i <= pageCount; i++) {
                                axios.get("/elapi/webshop-tree.json?type=flat&page=" + i)
                                    .then(response => {
                                        // Merge values of array into other array
                                        vm.categoriesTree.push.apply(vm.categoriesTree, response.data.data);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            }
                        } else {
                            p.resolve();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                // Brands categoryGroup = 3
                axios.get("/elapi/webshop-tree.json?type=flat&gid=3")
                    .then(response => {
                        vm.brandsTree = response.data.data;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            
            mobileNavLinkClicked(id, url) {
                let nextLevel = document.getElementById('lvl-' + id);
                if (nextLevel) {
                    // Save level
                    this.mobileLevelsOpen.push(nextLevel);
                    //this.openMobileNavLevel = this.mobileLevelsOpen[this.mobileLevelsOpen.length - 1].id;
                    
                    // Transition next level
                    nextLevel.classList.add('show');
                    
                    // Add active class only to the last panel shown
                    this.mobileLevelsOpen.forEach(el => {
                        el.classList.remove('active');
                    });
                    nextLevel.classList.add('active');
                    
                } else {
                    window.location = url;
                }
            },
            
            hasSubLevel(id) {
                let nextLevel = document.getElementById('lvl-' + id);
                return !!nextLevel;
            },
            
            mobileNavBackClicked() {
                // Set current level
                let currentLevel = this.mobileLevelsOpen[this.mobileLevelsOpen.length - 1];
                
                // Remove show class and transition out
                currentLevel.classList.remove('show');
                setTimeout(() => {
                    currentLevel.classList.remove('active');
                }, 350); // Transition default = 300ms
                
                // Remove level from array
                this.mobileLevelsOpen.pop();
            },
            
            setActiveAnchor(event) {
                let link = event.currentTarget;
                document.querySelector(link.hash).scrollIntoView({ behavior: "smooth", block: "center" });
                link.parentElement.classList.add('active');
            },
            
            switchPasswordVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            },
            
            stepperRemove() {
                // For some reason the returned stepperInput is of type String instead of Integer
                this.stepperInput = parseInt(this.stepperInput, 10);
                if (this.stepperInput > 1) this.stepperInput = this.stepperInput - 1;
            },
            
            stepperAdd() {
                // For some reason the returned stepperInput is of type String instead of Integer
                this.stepperInput = parseInt(this.stepperInput, 10);
                if (!this.$refs.stepperAdd.classList.contains('disabled')) this.stepperInput = this.stepperInput + 1;
            },
            
            onStepperInputChanged(event, stockCount) {
                if (parseInt(event.currentTarget.value) > stockCount) {
                    this.stepperInput = stockCount;
                    
                    this.$notify({
                        group: 'general-notices',
                        type: 'warning',
                        title: 'Beperkte voorraad!',
                        text: 'U heeft het maximaal aantal stuks van dit artikel in uw winkelwagen.'
                    });
                }
            },
            
            addToCart() {
                let form = document.getElementById('form-cart');
                let data = new FormData(form);
                
                axios.post('.', data)
                    .then(response => {
                        let success = response.data.success;
                        
                        if (!success) {
                            let errorLines = '<ul>';
                            for (let [key, value] of Object.entries(response.data.cart.errors)) {
                                errorLines = `${ errorLines }<li>${ value }</li>`;
                            }
                            errorLines = `${ errorLines }</ul>`;
                            
                            vm.$notify({
                                group: 'general-notices',
                                type: 'error',
                                title: response.data.error,
                                text: errorLines
                            });
                            
                        } else {
                            vm.$notify({
                                group: 'general-notices',
                                type: 'success',
                                title: 'Product toegevoegd',
                                text: 'Klik op het winkelwagentje om naar de checkout te gaan.'
                            });
                            
                            vm.updateCartCount();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            updateCartCount() {
                /* window.axios({
                 headers: { 'content-type': 'application/json' },
                 method: 'GET',
                 url: '/api/cart',
                 })
                 .then(response => {
                 console.log('with header', response, JSON.parse(response));
                 vm.cartCount = response.data.cartCount;
                 })
                 .catch(error => {
                 console.log(error);
                 });*/
                
                axios.get("/api/cart")
                    .then(response => {
                        if (response.data && response.data.cartCount) vm.cartCount = response.data.cartCount;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            
            checkIfAddressesExist() {
                axios.get("/api/addresses")
                    .then(response => {
                        if (response.data === 0) {
                            vm.showNewshippingAddressForm = true;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            
            setBirthday() {
                axios({
                    url: '/gqlapi',
                    method: 'post',
                    data: {
                        query: `
                        query {
                            users(id: ${ window.currentUserId }) {
                            ... on User {
                                    birthday
                                }
                            }
                        }`
                    }
                }).then(result => {
                    let birthday = result.data.data.users[0].birthday;
                    // FIXME: Wanneer de date wordt aangepast via het CP stuurt GraphQL (Twig niet) me de datum een dag eerder
                    if (birthday) {
                        vm.userBirthdayDay = parseInt(birthday.substr(8, 2), 10);
                        vm.userBirthdayMonth = parseInt(birthday.substr(5, 2), 10);
                        vm.userBirthdayYear = parseInt(birthday.substr(0, 4), 10);
                    }
                });
            },
            
            deleteLineItem(id) {
                document.getElementById('remove-' + id).value = 1;
                
                let form = document.getElementById('form-cart');
                let data = new FormData(form);
                
                axios.post('.', data)
                    .then(response => {
                        vm.updateCartCount();
                        location.reload(true);
                    })
                    .catch(error => {
                        console.log(error);
                        
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            deleteOfflineItem(type, photoIds, photoToDeleteId, itemId, itemQty) {
                let deleteAll = false;
                let fieldName = '';
                if (type === 'prescription') {
                    fieldName = 'photoPrescription';
                } else if (type === 'medicineroll') {
                    fieldName = 'photoMedicineroll';
                }
                
                // To delete an asset you actually set the ID's in a hidden field of the assets you want to keep
                // Filter the ID's of the photos to keep
                let photosToKeep = photoIds.filter(item => item !== `${ photoToDeleteId }`);
                
                // Remove 1 from quantity to keep cartCount correct
                let hiddenInputs = `<input type="hidden"
                                           name="lineItems[${ itemId }][qty]"
                                           value="${ itemQty - 1 }" />`;
                
                // Generate the hidden input fields
                if (photosToKeep.length > 0) {
                    photosToKeep.forEach(id => {
                        hiddenInputs = `${ hiddenInputs } <input type="hidden" name="fields[${ fieldName }][]" value="${ id }" />`;
                    });
                } else {
                    // Delete all
                    hiddenInputs = `${ hiddenInputs } <input type="hidden" name="fields[${ fieldName }][]" value="" />`;
                    deleteAll = true;
                }
                
                // Get the form
                let form = document.getElementById('form-cart');
                
                // Append the hidden inputs to the form
                form.insertAdjacentHTML('afterbegin', hiddenInputs);
                let data = new FormData(form);
                
                // Empty all fields
                if (deleteAll) {
                    if (type === 'prescription') {
                        data.set('fields[pickUpLocationPrescription][]', '');
                        data.set('fields[notePrescription]', '');
                    } else if (type === 'medicineroll') {
                        data.set('fields[firstName]', '');
                        data.set('fields[lastName]', '');
                        data.set('fields[phone]', '');
                        data.set('fields[email]', '');
                        data.set('fields[noteMedicinerole]', '');
                        data.set('fields[clientAppointment]', '0');
                        data.set('fields[doctor]', '');
                        data.set('fields[pickUpLocationMedicineroll][]', '');
                    }
                }
                
                axios.post('.', data)
                    .then(response => {
                        vm.updateCartCount();
                        location.reload(true);
                    })
                    .catch(error => {
                        console.log(error);
                        
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            updateCartAndCheckout() {
                let form = document.getElementById('form-cart');
                let data = new FormData(form);
                
                axios.post('.', data)
                    .then(response => {
                        window.location = '/webshop/checkout';
                    })
                    .catch(error => {
                        console.log(error);
                        
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            updateCartAndPay(cancelUrl, mail, gatewayId) {
                let form = document.getElementById('form-cart');
                let formData = new FormData(form);
                formData.set('gatewayId', gatewayId);
                
                axios.post('/actions/commerce/cart/update-cart', formData)
                    .then(response => {
                        if (response['status'] === 200) {
                            window.location = '/webshop/checkout/payment';
                        }
                    })
                
                /*let form = document.getElementById('form-cart');
                 let formData = new FormData(form);
                 
                 axios.post('/actions/commerce/cart/update-cart', formData)
                 .then(response => {
                 if (response['status'] === 200) {
                 let data = new FormData();
                 data.set('cancelUrl', cancelUrl);
                 data.set('orderEmail', mail);
                 data.set('gatewayId', gatewayId);
                 data.set('CRAFT_CSRF_TOKEN', window.csrfToken);
                 
                 axios.post('/actions/commerce/payments/pay', data)
                 .then(response => {
                 console.log('response', response)
                 window.location = '/webshop/checkout/confirmation';
                 })
                 .catch(error => {
                 console.log('error', error);
                 
                 vm.$notify({
                 group: 'general-notices',
                 type: 'error',
                 title: 'Fout!',
                 text: vm.generalErrorMsg
                 });
                 });
                 }
                 })*/
            },
            
            updateCart() {
                let form = document.getElementById('form-cart');
                let data = new FormData(form);
                
                axios.post('.', data)
                    .then(response => {
                        vm.updateCartCount();
                    })
                    .catch(error => {
                        console.log(error);
                        
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            saveCart(formId, redirectUrl) {
                let form = document.getElementById(formId);
                let data = new FormData(form);
                data.set('CRAFT_CSRF_TOKEN', window.csrfToken);
                
                axios.post('.', data)
                    .then(response => {
                        vm.updateCartCount();
                        window.location = '/' + redirectUrl;
                    })
                    .catch(error => {
                        console.log(error);
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            saveCartAndPay(formId, redirect, blobs, fieldName) {
                let form = document.getElementById(formId);
                let data = new FormData(form);
                let redirectUrl = data.get('redirectUrl');
                data.set('CRAFT_CSRF_TOKEN', window.csrfToken);
                data.set('action', 'commerce/cart/update-cart');
                
                if (blobs && blobs.length > 0) {
                    blobs.forEach((blob, index) => {
                        data.append(`fields[${ fieldName }][]`, blob, `prescription-${ index }.jpg`);
                    });
                }
                
                axios.post('.', data)
                    .then(response => {
                        data.set('action', 'commerce/payments/pay');
                        
                        axios.post('.', data)
                            .then(response => {
                                if (redirect) window.location = '/' + redirectUrl;
                            })
                            .catch(error => {
                                console.log(error);
                                vm.$notify({
                                    group: 'general-notices',
                                    type: 'error',
                                    title: 'Fout!',
                                    text: vm.generalErrorMsg
                                });
                            });
                    })
                    .catch(error => {
                        console.log(error);
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            async emptyCart() {
                return axios.get("/api/cart")
                    .then(response => {
                        let lineItemIds = response.data.lineItemIds;
                        
                        let formData = new FormData();
                        formData.set('CRAFT_CSRF_TOKEN', window.csrfToken);
                        formData.set('action', 'commerce/cart/update-cart');
                        
                        lineItemIds.forEach(lineItemId => {
                            formData.set('lineItems[' + lineItemId + '][remove]', 1);
                        });
                        
                        return formData;
                    })
                    .then(formData => {
                        return window.axios({
                            method: 'post',
                            headers: {
                                "Content-Type": 'application/x-www-form-urlencoded',
                            },
                            data: formData,
                            url: '',
                        }).then(() => {
                            vm.updateCartCount();
                            return true;
                        }).catch(error => {
                            console.log(error);
                            
                            vm.$notify({
                                group: 'general-notices',
                                type: 'error',
                                title: 'Fout!',
                                text: vm.generalErrorMsg
                            });
                            
                            return false;
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        return false;
                    });
            },
            
            onChangeQuantity(event, stockCount) {
                if (parseInt(event.currentTarget.value) === stockCount) {
                    this.$notify({
                        group: 'general-notices',
                        type: 'warning',
                        title: 'Beperkte voorraad!',
                        text: 'U hebt de laatste stuks van dit artikel in uw winkelwagen.'
                    });
                }
            },
            
            toggleBillingAddressForm() {
                let form = document.querySelector('.address-fieldset.BillingAddress');
                if (form.classList.contains('hidden')) {
                    form.classList.remove('hidden');
                } else {
                    form.classList.add('hidden');
                }
            },
            
            saveAddress() {
                let form = document.getElementById('form-edit-address');
                let data = new FormData(form);
                
                axios.post('.', data)
                    .then(response => {
                        vm.$notify({
                            group: 'general-notices',
                            type: 'success',
                            title: 'Adres bewaard.',
                            text: ''
                        });
                        
                        this.closeModalDefault();
                    })
                    .catch(error => {
                        console.log(error);
                        
                        vm.$notify({
                            group: 'general-notices',
                            type: 'error',
                            title: 'Fout!',
                            text: vm.generalErrorMsg
                        });
                    });
            },
            
            openModalDefault(urlToLoad) {
                this.$modal.show('modal-default', { 'urlToLoad': urlToLoad });
            },
            
            closeModalDefault() {
                this.$modal.hide('modal-default');
            },
            
            validateForm(shippingCatIds, redirect) {
                let vm = this;
                this.$refs.prescriptionFormObserver.validate().then(success => {
                    if (!success) {
                        return;
                    } else {
                        vm.checkCartConsistency(shippingCatIds, redirect);
                    }
                });
            },
            
            checkCartConsistency(shippingCatIds, redirect, blobs, fieldName) {
                /* Here we check what products are in the cart.
                 * If a product gets added with a different shipping category, we present the user
                 * with a choice between discarding the cart or cancel the current action.
                 * REASON: only products of type homePharmacy have a price and can be payed online
                 * others need to be payed in the shop and don't have a price yet.
                 */
                
                axios.get("/api/cart")
                    .then(response => {
                        /* Get all shippingCategories from the first product in the cart.
                         * We only look at the first product, because our logic doesn't allow for products
                         * with different shippingCategories to be added in the same cart.
                         * If products have multiple categories we need to take care of this later when setting the gateway:
                         * eg: mix of pickup-only products with products that can be pickup OR delivery -> gateway should be set to pickup
                         */
                        let shippingCatsInCart = response.data.cartProductShippingCats;
                        // Get all the ID's in an array
                        let shippingCatsInCartIds = [];
                        Object.keys(shippingCatsInCart).forEach(key => {
                            shippingCatsInCartIds.push(shippingCatsInCart[key].id);
                        });
                        
                        // Check if there is a match in categories
                        let shippingCatMatch = shippingCatsInCartIds.length > 0 ? shippingCatsInCartIds.filter(x => shippingCatIds.includes(x)).length > 0 : true;
                        
                        if (!shippingCatMatch) {
                            // Show warning
                            vm.$modal.show('dialog', {
                                title: 'Opgelet!',
                                text: `
                                    <p>U voegt een product toe dat betaald wordt bij ophaling. Er zitten echter producten in uw winkelwagen die
                                    moeten worden afgerekend via de webshop.</p>
                                    <p>Gelieve de betaling van deze producten eerst af te ronden.
                                    <br />Indien u toch verder gaat, zal uw winkelwagen eerst geleegd worden.</p>
                                 `,
                                buttons: [
                                    {
                                        title: 'Doorgaan',
                                        handler: () => {
                                            // Empty cart and submit form
                                            // !!! This can only be the case when adding prescriptions
                                            vm.emptyCart().then(success => {
                                                if (success) {
                                                    vm.saveCartAndPay('form-cart', true);
                                                }
                                            });
                                        }
                                    },
                                    {
                                        title: 'Annuleren',
                                        default: true, // Will be triggered by default if 'Enter' pressed.
                                        handler: () => {
                                            vm.$modal.hide('dialog');
                                        }
                                    }
                                ]
                            })
                            
                        } else {
                            // Check if we need to redirect else we just add it to the cart and stay on the same page
                            if (redirect) {
                                // If the only shippingCategory is pick-up
                                if (shippingCatIds.length === 1 && shippingCatIds[0] === '1') vm.saveCartAndPay('form-cart', redirect, blobs, fieldName);
                            } else {
                                vm.addToCart();
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            
            setFileLabel(event) {
                let input = event.currentTarget;
                this.prescriptionFileCount = input.files.length;
                if (input.files && input.files.length > 1) {
                    this.fileLabel = input.files.length + " bestanden geselecteerd";
                } else {
                    this.fileLabel = input.value.split('\\').pop();
                }
            }
        },
        
        computed: {
            getUserBirthday() {
                // Craft expects DD/MM/YYYY -> follows Site language
                return `${ this.userBirthdayDay }/${ this.userBirthdayMonth }/${ this.userBirthdayYear }`;
            }
        },
    });
    
    return vm;
};

// Execute async function
main().then((vm) => {
    // IE11 object-fit polyfill
    objectFitImages();
    
    /* Image component swiper */
    let imagesComponentSwiper = new Swiper('.swiper-container-general', {
        centeredSlides: true,
        a11y: true,
        watchOverflow: true,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        }
    })
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
